$darkGreen: #023937;
$lightGreen: #8AAEA0;
$textColor: #BDD2CA;
$headerWidth: 450px;
$headerHeight: 380px;

@font-face {
  font-family: 'Academy Engraved LET';
  font-style: normal;
  font-weight: 400;
  src: local('Academy Engraved LET'), url('https://fonts.cdnfonts.com/s/14505/AcademyEngravedLetPlain.woff') format('woff');
}


html, body {
  color: $textColor;
  background-color: $darkGreen;
}

h2 {
  font-family: 'kaoly_demoregular', sans-serif;
  font-size: 28px;
  margin: 60px 20px 40px;
  text-align: center;
  
  text-decoration: underline;
  text-underline-offset: 20px;
  text-decoration-thickness: from-font;
}

h3 {
  font-family: 'Academy Engraved LET', sans-serif;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}

p {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 18px;
}

.about-me {
  max-width: 950px;
  padding: 0 40px;
  margin: 60px auto 80px;
  line-height: 22px;
}

.disclaimer {
  margin: 60px 30px 80px;
  color: #ffac29;
  line-height: 22px;
  span {
    margin-top: 10px;
    display: block;
    font-size: 16px;
    font-style: italic;
  }
}

.centered {
  text-align: center;
}

.logo {
  width: 232px;
  margin: 0 auto;
  position: absolute;
  pointer-events: none;
  left: calc(50% - 116px);
  top: 30px;
  z-index: 1;
}

.page-bg {
  background-image: url('./assets/images/bg_horizontal.svg');
  background-size: 122px;
  transform: rotate(-45deg);
  position: fixed;
  top: -5000px;
  left: -5000px;
  width: 10000px;
  height: 10000px;
  pointer-events: none;
  opacity: .12;
}

.header-wrap {
  width: 100%;
  overflow: hidden;
  height: $headerHeight;
  background-color: $darkGreen;
}

.header-bg {
  position: relative;
  top: 0;
  z-index: 0;
  left: calc(50% - #{$headerWidth});
  max-width: 0px;
  margin-bottom: -$headerHeight;
  border-left: $headerWidth solid transparent;
  border-right: $headerWidth solid transparent;
  border-top: $headerHeight solid $lightGreen;

    &::before {
      content: '';
      position: absolute;
      top: calc(-#{$headerHeight} - 13px);
      left: -$headerWidth;
      border-left: $headerWidth solid transparent;
      border-right: $headerWidth solid transparent;
      border-top: $headerHeight solid $darkGreen;
    }
    &::after {
      content: '';
      position: absolute;
      top: calc(-#{$headerHeight} - 26px);
      left: -$headerWidth;
      border-left: $headerWidth solid transparent;
      border-right: $headerWidth solid transparent;
      border-top: $headerHeight solid $lightGreen;
    }
}

.menu {
  position: absolute;
  top: $headerHeight;
  width: 100%;
  height: calc(100vh - #{$headerHeight});
  box-sizing: border-box;
  padding-bottom: 100px;
  
  .menu-item {
    width: 80%;
    margin: 40px auto;
    text-align: center;
    
    .icon {
      margin-bottom: 15px;
    }

    p {
      line-height: 24px;
      &.ingredients {
        margin-top: 12px;
        font-size: 14px;
        font-style: italic;
        line-height: 18px;
      }
    }
  }
}

.other-cocktails {
  h2 {
    margin: 50px 20px 30px;
  }
  h3 {
    margin-bottom: 5px;
  }
  &:last-of-type {
    padding-bottom: 80px;
  }
}
