/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 17, 2022 */



@font-face {
    font-family: 'kaoly_demoregular';
    src: url('kaoly_demo-regular-webfont.woff2') format('woff2'),
         url('kaoly_demo-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}